<template>
    <div class="login-wrap" :style="`background:url(${themeImgs.loginRegBgImg})`">
        <div class="logo-box">
            <van-image :src="require('@siteResources/logo/loginReg_logo.png')" />
        </div>
        <div class="login-out-box">
            <div class="login-box">
                <van-field
                    v-model="loginParams.username"
                    :formatter="formatter"
                    clearable
                    center
                    autocomplete="off"
                    placeholder="请输入账号"
                    :left-icon="themeImgs.loginRegUserIcon"
                />
                <van-field
                    :type="inputType"
                    v-model="loginParams.password"
                    maxlength="16"
                    :formatter="formatter"
                    clearable
                    center
                    autocomplete="off"
                    placeholder="请输入密码"
                    :left-icon="themeImgs.loginRegKeyIcon"
                >
                    <template #button>
                        <span @click="changePassWordType" class="eye-icon">
                            <van-icon name="closed-eye" v-if="showEye" />
                            <van-icon name="eye-o" v-else />
                        </span>
                    </template>
                </van-field>
                <van-field
                    v-if="codeType==1"
                    v-model="loginParams.code"
                    maxlength="4"
                    :formatter="formatter"
                    center
                    autocomplete="off"
                    placeholder="请输入验证码"
                >
                    <template #extra>
                        <van-image class="code-img" :src="graphicCodeImg" @click="handleGetGraphicCode" />
                    </template>
                </van-field>
                <Geet ref="geetRef" v-show="codeType==3" @handleUpdateGeetStatus="handleUpdateGeetStatus" @handleGetGeetSession="handleGetGeetSession" />
                <div class="forget-box">
                    <van-checkbox name='rememberMe' v-model="isRememberMe">
                        记住密码
                    </van-checkbox>
                    <div class="service-forget" @click="setShowServicePopup(true)">联系客服</div>
                </div>
            </div>
        </div>
        <div class="btn login-btn-box" @click="handleLogin">登录</div>
        <router-link to="/register" class="btn reg-btn-box">注册</router-link>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onBeforeMount, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Icon, Toast } from "vant";
import { login, getGraphicCode, getCodeType, getMaintain, getScrap } from "@/api/api";
import wsConnection from "@/utils/websocket"
import Geet from "@/components/Geet"

export default defineComponent({
    components: {
        Geet,
        [Icon.name]: Icon,
    },
    setup() {
        const $router = useRouter();
        const store = useStore();
        const geetRef = ref()
        // 默认行为验证码状态
        const geetStatus = ref(1)
        // 改变行为验证码状态
        const handleUpdateGeetStatus = val => {
            geetStatus.value = val
        }
        // 存储验证码方式
        const codeType = ref('')
        // 获取验证码方式
        const handleGetCodeType = () => {
            getCodeType().then(res => {
                if(res.result == 200000) {
                    codeType.value = res.data.type
                    if(res.data.type==1) {
                        handleGetGraphicCode()
                    } else {
                        geetRef.value.init(res.data)
                        loginParams.session = res.data.session_id
                    }
                }
            })
        }
        // 获取极验证session
        const handleGetGeetSession = () => {
            handleGetCodeType()
        }
        // 存储图形验证码
        const graphicCodeImg = ref('')
        // 获取图形验证码
        const handleGetGraphicCode = () => {
            getGraphicCode().then(res => {
                if(res.result == 200000) {
                    graphicCodeImg.value = res.data.captcha
                    loginParams.session = res.data.session
                }
            })
        }

        onBeforeMount(()=> {
            handleGetCodeType()
            handleGetRemember()
        })
        // 过滤输入的中文
        const formatter = (value) => value.replace(/[\u4e00-\u9fa5]/g, "");
        const inputType = ref("password");
        const showEye = ref(true);
        const loginParams = reactive({
            username: "", //用户名
            password: "", //密码
            code: "", // 验证码
            session: "",
            is_captcha: 1
        });
        // 显示密码
        const changePassWordType = () => {
            showEye.value = !showEye.value;
            if (showEye.value) {
                inputType.value = "password";
            } else {
                inputType.value = "text";
            }
        };
        // 是否记住密码
        const isRememberMe = ref(true)
        const handleGetRemember = () => {
            if(isRememberMe.value) {
                loginParams.username = !!localStorage.getItem("username")?localStorage.getItem("username"):''
                loginParams.password = !!localStorage.getItem("password")?localStorage.getItem("password"):''
                loginParams.code = ''
                loginParams.session = ''
            } else {
                loginParams.username = ''
                loginParams.password = ''
                loginParams.code = ''
                loginParams.session = ''
            }

        }
        // 点击登录
        const handleLogin = () => {
            if (!loginParams.username) {
                return Toast.fail("请输入手机号码/邮箱");
            } else if (!loginParams.password) {
                return Toast.fail("请输入密码");
            } else if (codeType.value==1 && !loginParams.code) {
                return Toast.fail("请输入验证码");
            } else if (codeType.value==3 && geetStatus.value!=4) {
                return Toast.fail("请点击进行验证");
            } else {
                login(loginParams).then((res) => {
                    if (res.result == 200000) {
                        getScrap()
                        Toast.success("登录成功");
                        store.dispatch("saveLoginInfosActions", res.data);
                        $router.push("/home");
                        wsConnection.initWebSocket()
                        if(isRememberMe.value) {
                            localStorage.setItem("rememberMe", isRememberMe.value);
                            localStorage.setItem("username", loginParams.username);
                            localStorage.setItem("password", loginParams.password);
                        } else {
                            localStorage.removeItem("username");
                            localStorage.removeItem("password");
                            localStorage.removeItem("rememberMe");
                        }
                    } else {
                        if(codeType.value==3) {
                            handleGetCodeType()
                        } else {
                            handleGetGraphicCode()
                        }
                        Toast.fail(res.msg)
                    }
                }).catch(err=>{
                    console.log(err);
                })
            }
        }
        // 是否显示用户名或手机号/邮箱登录结构
        const showUsernameLogin = ref(true)
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)

      const setShowServicePopup = (flag) => {
        store.commit('setShowServicePopup', flag)
      }

        return {
            codeType,
            graphicCodeImg,
            handleGetGraphicCode,
            formatter,
            inputType,
            showEye,
            loginParams,
            changePassWordType,
            isRememberMe,
            handleLogin,
            geetStatus,
            handleUpdateGeetStatus,
            geetRef,
            handleGetGeetSession,
            showUsernameLogin,
            themeImgs,
            setShowServicePopup
        };
    },
});
</script>

<style lang="less" scoped>
.login-wrap {
    min-height: 100vh;
    padding: 0 25px;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    .logo-box {
        position: relative;
        text-align: center;
        .van-image {
            width: 168px;
            margin-top: 38px;
        }
    }
    .login-out-box {
        padding: 40px 15px;
        background: var(--outBoxBgColor);
        box-shadow: var(--outBoxShadow);
        border-radius: 30px;
        .login-box {
            :deep(.van-cell) {
                margin-bottom: 8px;
                padding-left: 5px;
                background: inherit;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                .van-field__control {
                    color: var(--logRegInputTextColor);
                }
                :deep(.van-field__left-icon) {
                    width: 24px;
                    height: 24px;
                    .van-icon__image {
                        width: 24px;
                        height: 24px;
                    }
                }
                :deep(.code-img) {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100px;
                    height: 45px;
                }
                &::after {
                    border: none;
                }
                input::-webkit-input-placeholder {
                    color: var(--logRegPlaceholderColor);
                }
                input:-ms-input-placeholder {
                    color: var(--logRegPlaceholderColor);
                }
                input:-moz-placeholder {
                    color: var(--logRegPlaceholderColor);
                    opacity: 1;
                }
                input::-moz-placeholder {
                    color: var(--logRegPlaceholderColor);
                    opacity: 1;
                }
                .eye-icon {
                    font-size: 18px;
                    color: var(--logRegEyeIconColor);
                }
            }
            .forget-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 15px;
                .service-forget {
                    font-size: 14px;
                    letter-spacing: 0.5px;
                    color: var(--forgetTextColor);
                }
            }
        }
    }
    .btn {
        width: 100%;
        height: 45px;
        margin-top: 24px;
        line-height: 45px;
        text-align: center;
        font-weight: 600;
        letter-spacing: 0.5px;
        border-radius: 41px;
        &.login-btn-box {
            background: var(--logRegBtnBgColor);
            color: var(--textColor1);
        }
        &.reg-btn-box {
            display: block;
            color: #fff;
            border: 2px solid var(--borderColor);
        }
    }
}
</style>
